import { Component, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DashboardCustomizationConst } from '../customizable-dashboard/DashboardCustomizationConsts';


@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent extends AppComponentBase {
    @Input() title: string;
    @Input() slug: string;
    @Input() imgUrl: string = 'assets/common/images/marker.png';
    dashboardName = DashboardCustomizationConst.dashboardNames.defaultTenantDashboard;

    constructor(injector: Injector) {
        super(injector);
    }

    getMobileMenuSkin(): string {
        return this.appSession.theme.baseSettings.layout.darkMode ? "dark" : "light";
    }
}
