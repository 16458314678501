<div class="header">
    <div class="flex flex-col gap-7 md:flex-row justify-between items-center py-8" [class]="containerClass">
        <div>
            <div class="flex items-center gap-6">
                <ng-content select="[slot=backBtn]"></ng-content>

                <img *ngIf="dashboardName != 'TenantDashboard'" src="assets/common/images/logo.png" alt="logo" />

                <p class="text-white mt-2" [ngClass]="dashboardName != 'TenantDashboard' ? 'text-[16px]' : 'text-[40px]'">
                    {{slug}}
                </p>
            </div>

          
        </div>

        <div class="flex gap-7">
            <div class="flex flex-col justify-center">
                <div class="*:text-white">
                    <span class="text-[32px] font-[100]">{{title}}</span>
                </div>

                <ng-content></ng-content>
            </div>
            <img *ngIf="imgUrl" src="{{imgUrl}}" alt="marker" />
        </div>
    </div>
</div>
