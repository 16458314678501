import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppHeaderComponent } from './app-header.component';


@NgModule({
    imports: [CommonModule],
    declarations: [AppHeaderComponent],
    exports: [AppHeaderComponent],
})
export class AppheaderModule { }
