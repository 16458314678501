import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'flaticon-line-graph',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),





            //new AppMenuItem('Cities', 'Pages.Cities', 'flaticon-more', '/app/main/area/cities'),
            new AppMenuItem(
                'Area',
                '', 'flaticon-more',
                '/app/main/area',
                [],
                [
                    new AppMenuItem('Countrieses', 'Pages.Countrieses', 'flaticon-more', '/app/main/area/countrieses'),
                    new AppMenuItem('Provinces', 'Pages.Provinces', 'flaticon-more', '/app/main/area/provinces'),
                    new AppMenuItem('Places', 'Pages.Places', 'flaticon-more', '/app/main/area/places'),
 ]
            ),

            new AppMenuItem('Driver', 'Pages.DriversManagement', 'flaticon-more', '/app/main/drivers/'),
            new AppMenuItem('CompanySettings', 'Pages.CompanySetting', 'flaticon-more', '/app/main/companySettings/'),

            new AppMenuItem('CraneCoveredAreas', 'Pages.CraneCoveredAreas', 'flaticon-more', '/app/main/area/craneCoveredAreas'),

            new AppMenuItem('FirebaseTokens', 'Pages.FirebaseTokens', 'flaticon-more', '/app/main/notifications/firebaseTokens'),

            new AppMenuItem('TenantFirebaseTokens', 'Pages.TenantFirebaseTokens', 'flaticon-more', '/app/main/notifications/tenantFirebaseTokens'),

            new AppMenuItem(
                'Cars',
                '', 'flaticon-more',
                '',
                [],
                [
                    new AppMenuItem('CarCategories', 'Pages.CarCategories', 'flaticon-more', '/app/main/cars/carCategories'),
                    new AppMenuItem('carClassifications', 'Pages.Classifications', 'flaticon-more', '/app/main/cars/carClassifications'),
                    new AppMenuItem('Nationalities', 'Pages.Nationalities', 'flaticon-more', '/app/main/area/nationalities'),

                    new AppMenuItem('Currencies', 'Pages.Currencies', 'flaticon-more', '/app/main/currencies/currencies'),
]
            ),
            new AppMenuItem(
                'Services',
                '', 'flaticon-more',
                '/app/main/Services',
                [],
                [
                    new AppMenuItem('PriceRanges', 'Pages.PriceRanges', 'flaticon-more', '/app/main/services/priceRanges'),

                    new AppMenuItem('CarExamples', 'Pages.CarExamples', 'flaticon-more', '/app/main/services/carExamples'),

                    new AppMenuItem('ServicePriceRanges', 'Pages.ServicePriceRanges', 'flaticon-more', '/app/main/services/servicePriceRanges'),
                ]
            ),

            new AppMenuItem('DocumentTypeses', 'Pages.DocumentTypeses', 'flaticon-more', '/app/main/documents/documentTypeses'),

            new AppMenuItem('BasicDriverInfos', 'Pages.BasicDriverInfos', 'flaticon-more', '/app/main/drivers/basicDriverInfos'),

            new AppMenuItem('DriverBankInfos', 'Pages.DriverBankInfos', 'flaticon-more', '/app/main/drivers/driverBankInfos'),

            new AppMenuItem('Bookings', 'Pages.Bookings', 'flaticon-more', '/app/main/bookings/bookings'),



            new AppMenuItem('DriverDocumentRequireds', 'Pages.DriverDocumentRequireds', 'flaticon-more', '/app/main/documents/driverDocumentRequireds'),

            new AppMenuItem('TenantDeliveryOptions', 'Pages.TenantDeliveryOptions', 'flaticon-more', '/app/main/delivery/tenantDeliveryOptions'),

            new AppMenuItem('TenantDeliveryModes', 'Pages.TenantDeliveryModes', 'flaticon-more', '/app/main/delivery/tenantDeliveryModes'),


            new AppMenuItem('DriverWallets', 'Pages.DriverWallets', 'flaticon-more', '/app/main/wallet/driverWallets'),

            new AppMenuItem('DriverWalletActions', 'Pages.DriverWalletActions', 'flaticon-more', '/app/main/wallet/driverWalletActions'),

            new AppMenuItem('ExternalTokenses', 'Pages.ExternalTokenses', 'flaticon-more', '/app/main/authorization/externalTokenses'),
           
            new AppMenuItem('CarSamples', 'Pages.CarSamples', 'flaticon-more', '/app/main/cars/carSamples'),
            
            new AppMenuItem('CraneServicesPrices', 'Pages.CraneServicesPrices', 'flaticon-more', '/app/main/services/craneServicesPrices'),
            
            new AppMenuItem('BookingsSessions', 'Pages.BookingsSessions', 'flaticon-more', '/app/main/bookings/bookingsSessions'),
            
            new AppMenuItem('BookingCustomers', 'Pages.BookingCustomers', 'flaticon-more', '/app/main/bookings/bookingCustomers'),
            
            new AppMenuItem('BeneficiaryBookings', 'Pages.BeneficiaryBookings', 'flaticon-more', '/app/main/bookings/beneficiaryBookings'),
            
            new AppMenuItem('DriversStatements', 'Pages.DriversStatements', 'flaticon-more', '/app/main/drivers/driversStatements'),
            
            new AppMenuItem('DeliveryTypes', 'Pages.DeliveryTypes', 'flaticon-more', '/app/main/delivery/deliveryTypes'),
            
            new AppMenuItem('BookingHistories', 'Pages.BookingHistories', 'flaticon-more', '/app/main/bookings/bookingHistories'),
             new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem(
                'Administration',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'flaticon-map',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    new AppMenuItem('ExternalTokenses', 'Pages.Administration.ExternalTokenses', 'flaticon-more', '/app/admin/authorization/externalTokenses'),

                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'flaticon-folder-1',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'flaticon-lock',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'flaticon-refresh',
                        '/app/admin/subscription-management'
                    ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'flaticon-medical',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'flaticon2-world',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'flaticon-interface-8',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'flaticon-settings',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'flaticon-settings',
                        '/app/admin/tenantSettings'
                    ),
                    new AppMenuItem(
                        'Notifications',
                        '',
                        'flaticon-alarm',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Inbox',
                                '',
                                'flaticon-mail-1',
                                '/app/notifications'
                            ),
                            new AppMenuItem(
                                'MassNotifications',
                                'Pages.Administration.MassNotification',
                                'flaticon-paper-plane',
                                '/app/admin/mass-notifications'
                            )
                        ]
                    )
                ]
            ),
            new AppMenuItem(
                'DemoUiComponents',
                'Pages.DemoUiComponents',
                'flaticon-shapes',
                '/app/admin/demo-ui-components'
            ),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
